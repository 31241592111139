import React, { useState } from "react"

import { Box, Spinner } from "@chakra-ui/react"
import axios from "axios"
import { useToastContext } from "src/hooks/useToastContext"
import Select from "react-select"

// Standard 5-part cron expressions (minute hour day-of-month month day-of-week)
const cadenceToSchedule = {
  "No preference for now": "0 12 1 1 *", // Run at 12:00 PM on January 1
  Daily: "0 12 * * *",
  Monthly: "0 12 1 * *",
  Quarterly: "0 12 1 */3 *",
}

const scheduleToCadence = {
  "0 12 1 1 *": "No preference for now",
  "0 12 * * *": "Daily",
  "0 12 1 * *": "Monthly",
  "0 12 1 */3 *": "Quarterly",
}

type CadenceSelectorProps = {
  etlJobConfigId: number
  initialSchedule: string
}

const getCadenceFromSchedule = (schedule: string): string => {
  return scheduleToCadence[schedule] || "No preference for now"
}

const CadenceSelector = ({ etlJobConfigId, initialSchedule }: CadenceSelectorProps) => {
  const initialCadence = getCadenceFromSchedule(initialSchedule)
  const [selectedCadence, setSelectedCadence] = useState(initialCadence)
  const [isUpdating, setIsUpdating] = useState(false)
  const { showSuccess, showError } = useToastContext()

  const handleCadenceChange = async (option) => {
    const newCadence = option.value

    setSelectedCadence(newCadence)
    const schedule = cadenceToSchedule[newCadence]

    setIsUpdating(true)

    try {
      await axios.patch("/api/etl-in/update-connection", {
        etlJobConfigId,
        schedule,
      })

      showSuccess(`Sync schedule has been set to ${newCadence}`)
    } catch (error) {
      console.error("Error updating sync schedule:", error)
      showError("An error occurred while updating the sync schedule. Please try again.")
      // Revert the UI to the previous state
      setSelectedCadence(initialCadence)
    } finally {
      setIsUpdating(false)
    }
  }

  return (
    <Box display="flex" alignItems="center" width="200px">
      <Select
        id="cadence"
        value={{ value: selectedCadence, label: selectedCadence }}
        onChange={handleCadenceChange}
        options={Object.keys(cadenceToSchedule).map((cadence) => ({
          value: cadence,
          label: cadence,
        }))}
        isDisabled={isUpdating}
        styles={{
          container: (provided) => ({
            ...provided,
            width: "160px",
            marginLeft: "8px",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#DDEBFF" : provided.backgroundColor,
          }),
        }}
        menuPosition="fixed"
        formatOptionLabel={({ label }) => <span>{label}</span>}
      />
      {isUpdating && <Spinner size="sm" ml={2} />}
    </Box>
  )
}

export default CadenceSelector
