import useSWR from "swr"
import { getWorkflowsByUser, QueryParams } from "pages/api/models/workflows/queries/getWorkflowsByUser"

import { cacheKey, returnedQuery } from "../types"

export default function useGetWorkflowsByUser(params: QueryParams, cacheKey: cacheKey): returnedQuery {
  const { data, error, isLoading } = useSWR([params, cacheKey], ([params]) => {
    if (!params.userId) {
      return
    }

    return getWorkflowsByUser(params)
  })

  return {
    workflows: data,
    isLoading,
    isError: !!error,
    error,
  }
}
