import { Box } from "@chakra-ui/react"
import { FiCheck, FiSlash, FiCircle } from "react-icons/fi"

export const CompletedIcon: React.FC = (): React.ReactElement => {
  return (
    <>
      <Box background="var(--secondary)" borderRadius="50%" p="2px">
        <FiCheck color="white" fontSize="12px" />
      </Box>
    </>
  )
}

export const InProgressIcon: React.FC = (): React.ReactElement => {
  return (
    <>
      <Box position="relative" bg="var(--warning)" w="16px" h="16px" borderRadius="50%" display="flex" alignItems="center" justifyContent="center">
        <Box position="absolute" bg="white" w="12px" h="6px" borderRadius="50% 50% 16px 16px" bottom="4x" left="5px" overflow="hidden" transform="rotate(-90deg)" transformOrigin="center" />
      </Box>
    </>
  )
}

export const BlockedIcon: React.FC = (): React.ReactElement => {
  return (
    <>
      <Box background="var(--error)" borderRadius="50%" p="2px">
        <FiSlash color="white" fontSize="12px" />
      </Box>
    </>
  )
}

export const NotStartedIcon: React.FC = () => {
  return <FiCircle color="grey" fontSize="16px" />
}
