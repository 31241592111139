import React, { useRef } from "react"

import { FiPlus } from "react-icons/fi"
import { User } from "@prisma/client"
import { Box, FormControl, FormErrorMessage, FormLabel, Textarea, useDisclosure } from "@chakra-ui/react"
import { Formik, Form, Field, FormikProps } from "formik"
import { selectUser } from "src/store/slices/userSlice"
import { useAppSelector } from "src/store/hooks"
import useGetOrganizationById from "src/hooks/getOrganizations/useGetOrganizationById"
import { trackEvent } from "lib/clients/mixpanel"
import { validateEmail } from "lib/emails"
import { handleClientSideException } from "lib/errors"
import { addUserToOrganization } from "pages/api/models/organizations/mutations/addUserToOrganization"
import { useToastContext } from "src/hooks/useToastContext"

import SecondaryButton from "../Buttons/Secondary"
import BasicModal from "../MenusAndModals/BasicModal"

const InviteMemberCard = () => {
  const formikRef = useRef<FormikProps<{ organizationId: string; emails: string }> | null>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { showError } = useToastContext()

  const activeUser = useAppSelector(selectUser) as User
  const organizationId = activeUser?.organizationId

  const { organization } = useGetOrganizationById({ id: organizationId || -1 }, "getOrganization")

  const activeOrganizationId = organization?.id

  const validateEmails = (value): string | null => {
    if (!value) {
      return "At least one email is required"
    }

    const emailArray = value.split(",")

    for (const email of emailArray) {
      const trimmedEmail = email.trim()

      if (!validateEmail(trimmedEmail)) {
        return "Invalid email(s)"
      }
    }

    return null
  }

  return (
    <>
      <SecondaryButton
        onClickHandler={() => {
          trackEvent("Clicked Invite To Organization")
          onOpen()
        }}
      >
        <FiPlus size="16" style={{ marginRight: "8px" }} /> Invite
      </SecondaryButton>
      {isOpen && (
        <BasicModal
          size="xl"
          title="Invite Team Members"
          onClose={() => onClose()}
          showActionButtons
          actionText="Send Invites"
          onAction={() => formikRef.current?.submitForm()}
          isActionLoading={formikRef.current?.isSubmitting}
          isActionDisabled={formikRef.current?.isSubmitting}
        >
          <Formik
            innerRef={formikRef}
            initialValues={{
              organizationId: String(activeOrganizationId),
              emails: "",
            }}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, actions) => {
              try {
                trackEvent("Clicked Send Invites")

                const invitePromises = values.emails.split(",").map((email) => {
                  return addUserToOrganization({
                    organizationId: parseInt(values.organizationId),
                    email: email.trim(),
                    role: "VIEWER",
                  })
                })

                await Promise.all(invitePromises)

                location.reload()
              } catch (error) {
                handleClientSideException(error, "Unable to add user to organization", showError)
              } finally {
                actions.setSubmitting(false)
                onClose()
              }
            }}
          >
            {(props) => (
              <Form>
                <Box my={4}>
                  <Field name="emails" validate={validateEmails}>
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.emails}>
                        <FormLabel htmlFor="emails">Emails</FormLabel>
                        <Textarea {...field} id="emails" placeholder="email@example.com, email2@example.com, etc." />
                        <FormErrorMessage>{form.errors.emails}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
              </Form>
            )}
          </Formik>
        </BasicModal>
      )}
    </>
  )
}

export default InviteMemberCard
