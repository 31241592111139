import { useState } from "react"

import { HStack, Spinner, Text, useToast, VStack } from "@chakra-ui/react"
import type { IntakeRequirement } from "@prisma/client"
import { updateIntakeRequirement } from "pages/api/models/intakeRequirements/mutations/updateIntakeRequirement"
import { handleClientSideException } from "lib/errors"
import { useToastContext } from "src/hooks/useToastContext"
import axios from "axios"
import revalidateSWRQueryByCacheKey from "src/helpers/revalidateSWRQueryByCacheKey"

import Callout from "../Indicators/Callout"
import Collapsible from "../Collapsible"
import DataManagementAndHandlingConfirmation from "./DataManagementAndHandlingConfirmation"
import { FileDropzone } from "../UserInteractable/FileDropzone"

const requirementsWithFileUpload = ["soc2", "security_documentation"]

const IntakeCallout = ({ organizationName, intakeRequirements, organizationId }: { organizationName: string; intakeRequirements: IntakeRequirement[]; organizationId: number }) => {
  const [hiddenRequirements, setHiddenRequirements] = useState<Set<string>>(new Set())

  const { showSuccess, showError } = useToastContext()
  const toast = useToast()

  const markRequirementCompleted = async (intakeRequirementId: number) => {
    try {
      const intakeRequirementType = intakeRequirements.find((r) => r.id === intakeRequirementId)!.type

      await updateIntakeRequirement({ intakeRequirementId, isCompleted: true })

      showSuccess("Requirement completed! It can be viewed in the Organization section below.")
      setHiddenRequirements((prev) => new Set([...prev, intakeRequirementType]))

      await revalidateSWRQueryByCacheKey("getOrganization")

      // wait for repaint
      setTimeout(() => {
        window.scrollTo({
          top: Math.max(document.body.scrollHeight, document.documentElement.scrollHeight, document.body.offsetHeight, document.documentElement.offsetHeight),
          behavior: "smooth",
        })

        // Find and highlight the completed requirement
        const completedElement = document.querySelector(`[data-requirement-id="${intakeRequirementId}"]`)

        if (completedElement) {
          completedElement.classList.add("requirement-highlight")

          setTimeout(() => {
            completedElement.classList.remove("requirement-highlight")
          }, 2000)
        }
      }, 50)
    } catch (error) {
      handleClientSideException(error, "Error marking requirement as completed:", showError)
    }
  }

  if (!intakeRequirements || intakeRequirements.length === 0) {
    return null
  }

  const formatRequirementTitle = (type: string): string => {
    const titles = {
      soc2: "Upload SOC2",
      security_documentation: "Upload Security documentation",
      data_management_and_handling_confirmation: "Data Management and Handling Confirmation",
      other: "Other requirement",
    }

    return titles[type] ?? "Undefined requirement"
  }

  const formatInfoText = (type: string): string => {
    const infoTexts = {
      soc2: "The System and Organization Controls 2, which verifies your security protocols",
      security_documentation: "Any other documentation of compliance work, such as penetration tests or security policies",
      data_management_and_handling_confirmation: "Attest to the legal policies required by Integral to issue any Expert Determination Reports",
      other: "Additional documentation required for compliance",
    }

    return infoTexts[type] ?? ""
  }

  const uploadIntakeDocuments = async (intakeRequirementId: number, files: File[]) => {
    // limit to 1 file
    if (files.length > 1) {
      showError("Please upload only one file.")
      return
    }

    try {
      toast({
        title: "Uploading file...",
        status: "info",
        duration: 3000,
        render: () => (
          <HStack
            backgroundColor="var(--green-2)"
            color="white"
            boxShadow="0 0 15px lightgray"
            borderRadius="8px"
            fontWeight="500"
            p="12px 20px"
            alignItems="center"
            userSelect="none"
            pointerEvents="none"
            justifyContent="center"
            width="fit-content"
            margin="auto"
            mb="12px"
          >
            <Spinner size={"sm"} />{" "}
            <Text ml="8px" color="white">
              Uploading...
            </Text>
          </HStack>
        ),
      })

      // upload file to s3
      const documentsWithFilesAttached = files.map((file, index) => {
        return {
          documentName: file.name,
          intakeRequirementId: intakeRequirementId,
          file: file,
        }
      })

      await axios.postForm(`/api/intake-documents`, {
        documents: documentsWithFilesAttached,
      })

      await markRequirementCompleted(intakeRequirementId)

      showSuccess("File uploaded successfully")
    } catch (error) {
      handleClientSideException(error, "Error uploading documents:", showError)
    }
  }

  const getCollapsibleContent = (requirement: IntakeRequirement) => {
    if (requirement.type === "data_management_and_handling_confirmation") {
      return <DataManagementAndHandlingConfirmation organizationName={organizationName} onConfirm={() => markRequirementCompleted(requirement.id)} />
    }

    if (requirementsWithFileUpload.includes(requirement.type)) {
      return (
        <FileDropzone
          height="292px"
          onDrop={async (files) => {
            await uploadIntakeDocuments(requirement.id, files)
          }}
          filePickerMask={{
            "application/pdf": [".pdf"],
            "application/docx": [".docx"],
            "application/doc": [".doc"],
          }}
        />
      )
    }

    return <Text>Nothing to see here.</Text>
  }

  return (
    <Callout title={`Complete requirements for ${organizationName}`}>
      <Text fontStyle="italic">
        Fulfill these requirements to allow Integral to efficiently bring your data to compliance.
        <br />
        Completed requirements are moved to the &quot;This Organization&quot; section below
      </Text>
      <VStack justifyContent="flex-start" alignItems="left" marginTop="16px" gap="16px">
        {intakeRequirements.map(
          (requirement) =>
            !hiddenRequirements.has(requirement.type) &&
            !requirement.isCompleted && (
              <Collapsible
                key={requirement.id}
                title={formatRequirementTitle(requirement.type)}
                infoText={formatInfoText(requirement.type)}
                isInitiallyOpen={false}
                skipButtonText={requirement.isOptional ? "Nothing to include for now, mark complete" : undefined}
                onSkip={requirement.isOptional ? () => markRequirementCompleted(requirement.id) : () => {}}
                displayOptions={{
                  completion: false,
                  lastEdited: false,
                }}
              >
                {getCollapsibleContent(requirement)}
              </Collapsible>
            ),
        )}
      </VStack>
    </Callout>
  )
}

export default IntakeCallout
