import { useEffect, useState } from "react";
import { Box, HStack, Spacer, Text } from "@chakra-ui/react";
import { User } from "@prisma/client";
import { useRouter } from "next/router";
import axios from "axios";
import StartWorkflowCard from "src/components/Home/StartWorkflowCard";
import HomeLayout from "src/layouts/HomeLayout";
import ExpertDeterminationsTable from "src/components/Home/ExpertDeterminationsTable";
import OrganizationUsersTable from "src/components/Home/OrganizationUsersTable";
import { useAppSelector } from "src/store/hooks";
import { selectUser } from "src/store/slices/userSlice";
import InviteMemberCard from "src/components/Home/InviteMemberCard";
import useGetOrganizationById from "src/hooks/getOrganizations/useGetOrganizationById";
import useGetWorkflowsByUser from "src/hooks/getWorkflows/useGetWorkflowsByUser";
import Banner from "src/components/Indicators/Banner";
import { useToastContext } from "src/hooks/useToastContext";
import DataSourcesTable from "src/components/Home/DataSourcesTable";
import ConnectDataSource from "src/components/Home/ConnectDataSource";
import useGetFeatureFlagState from "src/hooks/getFeatureFlags/useGetFeatureFlagState";
import { handleClientSideException } from "lib/errors";
import EmptyStatePlaceholder from "src/components/Loading/EmptyStatePlaceholder";
import IntakeCallout from "src/components/Home/IntakeCallout";
import IntakeDocumentsTable from "src/components/Home/IntakeDocumentsTable";
import NewUploadCard from "src/components/Home/NewUploadCard";
const HomePage = () => {
  const {
    data: flagState
  } = useGetFeatureFlagState("app:getFeatureFlagState");
  const easyDataOnboardingEnabled = flagState?.state["easy-data-onboarding-june-11"];
  const intakeEnabled = flagState?.state["intake-nov-13"];
  const [dataSources, setDataSources] = useState([]);
  const [isLoadingDataSources, setIsLoadingDataSources] = useState(true);
  const {
    showError
  } = useToastContext();
  const activeUser = (useAppSelector(selectUser) as User);
  const {
    organization
  } = useGetOrganizationById({
    id: activeUser?.organizationId || -1
  }, "getOrganization");
  const organizationUsers = organization?.users;
  const availableWorkflows = useGetWorkflowsByUser({
    userId: activeUser?.id
  }, "app:getWorkflowsByUser").workflows;
  const router = useRouter();
  const {
    showSuccess
  } = useToastContext();
  const legalRequirements = organization?.intakeRequirements.filter(requirement => requirement.type !== "data_dictionary") ?? [];
  const intakeDocuments = legalRequirements.flatMap(requirement => requirement.intakeDocuments);
  const incompleteRequirements = legalRequirements.filter(requirement => !requirement.isCompleted);

  // lets also get the `data_management_and_handling_confirmation` requirement if completed and pretend its also a document
  const completedAttestation = legalRequirements.find(requirement => requirement.type === "data_management_and_handling_confirmation" && requirement.isCompleted);
  let attestationAsDocument = (null as any);
  if (completedAttestation) {
    attestationAsDocument = {
      id: -1,
      intakeRequirement: completedAttestation,
      file: null
    };
  }
  const intakeDocumentsAndAttestation = [...(intakeDocuments || []), ...(attestationAsDocument ? [attestationAsDocument] : [])];
  useEffect(() => {
    const fetchDataSources = async () => {
      try {
        const response = await axios.get("/api/etl-in/sources");
        setDataSources(response.data);
      } catch (error) {
        handleClientSideException(error, "Error fetching data sources:", showError);
      } finally {
        setIsLoadingDataSources(false);
      }
    };
    if (easyDataOnboardingEnabled) {
      void fetchDataSources();
    }
  }, [easyDataOnboardingEnabled, showError]);

  // Redirect if deprecated "workflow" query param is present
  useEffect(() => {
    if (router.isReady && router.query.workflow) {
      showSuccess("Redirecting you...");
      const {
        workflow
      } = router.query;
      const newPath = `/app/workflow/${workflow}/`;
      console.warn("Using deprecated query param. Redirecting to new route.");
      void router.replace(newPath);
    }
  }, [router, showSuccess]);
  return <>
      {intakeEnabled && incompleteRequirements?.length > 0 && <Box maxWidth="var(--website-width-max)" width="100%" margin="auto" textAlign="left" paddingTop="24px">
          <IntakeCallout organizationName={organization?.name} intakeRequirements={legalRequirements} organizationId={organization?.id} />
        </Box>}
      {availableWorkflows?.length === 0 && <Banner>
          <Box maxWidth="var(--website-width-max)" width="100%" margin="auto" textAlign="left">
            <Text color="white" fontWeight="500">
              Welcome to the {organization?.name} Expert Determination Portfolio. Start a project to begin.
            </Text>
          </Box>
        </Banner>}
      <Box maxWidth="var(--website-width-max)" margin="auto" paddingTop="32px" paddingBottom="64px">
        <HStack justifyContent="space-between">
          <Text fontSize="20px" fontWeight="bold">
            Expert Determinations
          </Text>
          {availableWorkflows?.length > 0 && <StartWorkflowCard />}
        </HStack>
        {availableWorkflows?.length ? <ExpertDeterminationsTable expertDeterminations={availableWorkflows} /> : <EmptyStatePlaceholder>
            <StartWorkflowCard />
          </EmptyStatePlaceholder>}
        {easyDataOnboardingEnabled && <>
            <Spacer height="40px" />
            <HStack justifyContent="space-between">
              <Text fontSize="20px" fontWeight="bold">
                Datasources
              </Text>
              {dataSources?.length > 0 && <ConnectDataSource />}
            </HStack>
            {isLoadingDataSources ? <EmptyStatePlaceholder>
                <Box>Loading...</Box>
              </EmptyStatePlaceholder> : dataSources.length > 0 ? <DataSourcesTable dataSources={dataSources} /> : <EmptyStatePlaceholder>
                <ConnectDataSource />
              </EmptyStatePlaceholder>}
          </>}

        <Spacer height="40px" />
        <HStack justifyContent="space-between">
          <Text fontSize="20px" fontWeight="bold">
            This Organization
          </Text>
          <InviteMemberCard />
        </HStack>
        {organizationUsers?.length > 0 ? <OrganizationUsersTable users={organizationUsers} /> : <EmptyStatePlaceholder>
            <InviteMemberCard />
          </EmptyStatePlaceholder>}
        {intakeEnabled && intakeDocumentsAndAttestation?.length > 0 && <>
            <Spacer height="24px" />
            <HStack justifyContent="flex-end">
              <NewUploadCard intakeRequirements={legalRequirements} />
            </HStack>
            <IntakeDocumentsTable intakeDocuments={intakeDocumentsAndAttestation} />
          </>}
      </Box>
    </>;
};
HomePage.suppressFirstRenderFlicker = true;
HomePage.getLayout = (page: JSX.Element) => <HomeLayout>{page}</HomeLayout>;
export default HomePage;