import { useState } from "react"

import { Image } from "@chakra-ui/react"
import { DatabricksJobRun, DataSource, ETLJobConfig, ETLJobRun } from "@prisma/client"
import { dataSourceConnectors } from "lib/dataSourceConnectors"
import { humanizeTimestamp } from "lib/dates"
import axios from "axios"
import { useToastContext } from "src/hooks/useToastContext"
import { useRouter } from "next/router"
import useGetFeatureFlagState from "src/hooks/getFeatureFlags/useGetFeatureFlagState"

import Tooltip from "../Indicators/Tooltip"
import CadenceSelector from "../DataConnection/CadenceSelector"
import TertiaryButton from "../Buttons/Tertiary"

type DataSourceWithStatus = DataSource & {
  etlJobConfigs: (ETLJobConfig & {
    etlJobRuns: (ETLJobRun & {
      DatabricksJobRun: DatabricksJobRun
    })[]
    sourceType: string
  })[]
}

const DataSourcesRow = ({ dataSource }: { dataSource: DataSourceWithStatus }) => {
  const [isSyncing, setIsSyncing] = useState(false)
  const etlJobConfig = dataSource.etlJobConfigs[0]
  const { data: flagState } = useGetFeatureFlagState("app:getFeatureFlagState")
  const dataDictionariesEnabled = flagState?.state["data-dictionaries-dec-17"]
  const router = useRouter()

  if (!etlJobConfig) {
    return null
  }
  const [lastRunTimestamp, setLastRunTimestamp] = useState(etlJobConfig.etlJobRuns[0]?.DatabricksJobRun.updatedAt || null)
  const { showSuccess, showError } = useToastContext()

  const getIconInfoBySourceType = (sourceType) => {
    return dataSourceConnectors.find((connector) => connector.sourceType === sourceType)
  }

  const iconSrc = getIconInfoBySourceType(etlJobConfig.sourceType)

  const handleSync = async () => {
    setIsSyncing(true)

    try {
      await axios.post(`/api/etl-job-configs/${etlJobConfig.id}/run`)
      setLastRunTimestamp(new Date())
      showSuccess("Sync started!")
    } catch {
      showError("Failed to trigger sync")
    } finally {
      setIsSyncing(false)
    }
  }

  return (
    <tr
      style={{
        borderTop: "1px solid var(--card-border)",
        cursor: dataDictionariesEnabled ? "pointer" : "default",
      }}
      onClick={async () => {
        if (!dataDictionariesEnabled) return
        await router.push(`/app/data-sources/${dataSource.uuid}`)
      }}
    >
      <td
        style={{
          padding: "12px 24px",
          width: "300px",
        }}
      >
        {dataSource.name}
      </td>
      <td
        style={{
          padding: "12px 0px",
          width: "400px",
        }}
      >
        {etlJobConfig.sourcePath}
      </td>
      <td
        style={{
          padding: "12px 0px",
          width: "100px",
        }}
      >
        <Tooltip label={iconSrc?.name}>
          <Image cursor="pointer" height="32px" mb="4px" alt={dataSource.name} src={iconSrc?.icon || ""} />
        </Tooltip>
      </td>
      <td style={{ width: "200px" }}>{lastRunTimestamp ? humanizeTimestamp(lastRunTimestamp) : "Ready to Sync"}</td>
      <td
        style={{
          padding: "12px 0px",
          width: "350px",
        }}
      >
        <div style={{ display: "flex", marginLeft: "-8px" }} onClick={(e) => e.stopPropagation()}>
          <div style={{ width: "200px" }}>
            <CadenceSelector etlJobConfigId={etlJobConfig.id} initialSchedule={etlJobConfig.cronExpression || ""} />
          </div>
          <TertiaryButton size="md" onClick={handleSync} loadingText="Syncing..." isLoading={isSyncing} isDisabled={isSyncing}>
            Sync
          </TertiaryButton>
        </div>
      </td>
    </tr>
  )
}

export default DataSourcesRow
