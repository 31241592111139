import dayjs, { OpUnitType } from "dayjs"
import * as pluralize from "pluralize"

const FULL_DATE_FORMAT_DEFAULT = "MMM D"
const UNITS: Record<string, OpUnitType> = {
  MINUTE: "m",
  HOUR: "h",
  DAY: "d",
  WEEK: "w",
}

export const humanizeTimestamp = (timestamp: string | number | Date, fullDateFormat = FULL_DATE_FORMAT_DEFAULT): string => {
  if (!timestamp) return "unknown"

  const createdAtTime = dayjs(timestamp)
  const now = dayjs()
  let suffix = "ago"

  if (now.diff(createdAtTime) < 0) {
    suffix = "from now"
  }

  if (Math.abs(now.diff(createdAtTime, UNITS.MINUTE)) < 1) {
    return "now"
  }

  if (Math.abs(now.diff(createdAtTime, UNITS.HOUR)) < 1) {
    const minutes = Math.abs(now.diff(createdAtTime, UNITS.MINUTE))

    return `${minutes} ${pluralize("minute", minutes)} ${suffix}`
  }

  if (Math.abs(now.diff(createdAtTime, UNITS.DAY)) < 1) {
    const hours = Math.abs(now.diff(createdAtTime, UNITS.HOUR))

    return `${hours} ${pluralize("hour", hours)} ${suffix}`
  }

  if (Math.abs(now.diff(createdAtTime, UNITS.WEEK)) < 1) {
    const days = Math.abs(now.diff(createdAtTime, UNITS.DAY))

    return `${days} ${pluralize("day", days)} ${suffix}`
  }

  return createdAtTime.format(fullDateFormat)
}

export const formatDays = (timestamp: string | number | Date): string => {
  if (!timestamp) return "unknown"

  const now = dayjs()
  const days = Math.abs(now.diff(timestamp, UNITS.DAY))

  if (days === 0) return "today"

  const daysPhrase = `${days} ${pluralize("day", days)}`

  if (now.diff(timestamp) < 0) {
    return `in ${daysPhrase}`
  }
  return `${daysPhrase} ago`
}

export const convertUnixEpochToTimestamp = (timestamp) => {
  return dayjs.unix(timestamp).format("M/D/YY")
}
