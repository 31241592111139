import { User } from "@prisma/client"

import Table from "../Tables/Table"
import OrganizationUserRow from "./OrganizationUserRow"

const OrganizationUsersTable = ({ users }: { users: User[] }) => {
  const tableHeaders = [{ label: "Name" }, { label: "Title" }, { label: "Contact" }]

  return (
    <Table columnHeaders={tableHeaders} headerIndent="24px">
      {users.map((user) => (
        <OrganizationUserRow key={user.id} user={user} />
      ))}
    </Table>
  )
}

export default OrganizationUsersTable
