import { useState } from "react"

import { FiPlus } from "react-icons/fi"
import { Text, Button, Image, useDisclosure, SimpleGrid, Box } from "@chakra-ui/react"
import { useRouter } from "next/router"
import useGetFeatureFlagState from "src/hooks/getFeatureFlags/useGetFeatureFlagState"
import { trackEvent } from "lib/clients/mixpanel"
import { dataSourceConnectors } from "lib/dataSourceConnectors"

import SecondaryButton from "../Buttons/Secondary"
import BasicModal from "../MenusAndModals/BasicModal"
import { H4Emphasized } from "../Text"
import Tooltip from "../Indicators/Tooltip"
import PrimaryButton from "../Buttons/Primary"

const ConnectDataSource = () => {
  const { data: flagState } = useGetFeatureFlagState("app:getFeatureFlagState")
  const easyDataOnboardingEnabled = flagState?.state["easy-data-onboarding-june-11"]
  const azureEnabled = flagState?.state["easy-data-onboarding-azure-jan-20"]

  const ENABLED_DATA_SOURCES = ["S3", "GCS", ...(azureEnabled ? ["Azure Blob Storage"] : [])]

  const { isOpen, onOpen, onClose } = useDisclosure()
  const router = useRouter()

  const [selectedDatasource, setSelectedDatasource] = useState<string | null>(null)

  const handleSelect = (name) => {
    setSelectedDatasource(name)
  }

  const handleContinue = async () => {
    if (!selectedDatasource) return

    const sourceParam = selectedDatasource.toLowerCase().replace(/\s+/g, "-")

    await router.push(`/app/connect-data-source?source=${sourceParam}`)
    onClose()
  }

  const getIsDisabled = (sourceName: string) => !ENABLED_DATA_SOURCES.includes(sourceName)

  if (!easyDataOnboardingEnabled) {
    return null
  }

  return (
    <>
      <SecondaryButton
        onClickHandler={() => {
          trackEvent("Clicked Connect to Datasource")
          onOpen()
        }}
      >
        <FiPlus size="16" style={{ marginRight: "8px" }} /> Connect a datasource
      </SecondaryButton>
      {isOpen && (
        <BasicModal showBorder={false} size="xl" title="Datasource Type" onClose={() => onClose()}>
          <H4Emphasized>Select the type of source you want to connect</H4Emphasized>
          <SimpleGrid columns={3} spacing={5} mt={5}>
            {dataSourceConnectors.map((source, index) => {
              const isDisabled = getIsDisabled(source.name)

              return (
                <Tooltip key={index} label={isDisabled ? "Coming soon" : ""}>
                  <Button
                    variant={selectedDatasource === source.name ? "white" : "ghost"}
                    border={selectedDatasource === source.name ? "1px solid #8C4CCD" : "transparent"}
                    boxShadow={selectedDatasource === source.name ? "0px 4px 4px rgba(0, 0, 0, 0.15)" : "none"}
                    flexDirection="column"
                    height="125px"
                    onClick={() => handleSelect(source.name)}
                    isDisabled={isDisabled}
                    opacity={isDisabled ? 0.5 : 1}
                    _hover={{ opacity: isDisabled ? 0.5 : 0.8 }}
                  >
                    <Image src={source.icon} alt={source.name} boxSize="50px" mb={2} mx="auto" />
                    <Box textAlign="center" maxW="100px" whiteSpace="pre-wrap" overflowWrap="break-word">
                      <Text>{source.name}</Text>
                    </Box>
                  </Button>
                </Tooltip>
              )
            })}
          </SimpleGrid>
          <Box marginTop="32px" display="flex" justifyContent="flex-end">
            <PrimaryButton isDisabled={!selectedDatasource} colorScheme="purple" onClick={handleContinue}>
              Continue with datasource
            </PrimaryButton>
          </Box>
        </BasicModal>
      )}
    </>
  )
}

export default ConnectDataSource
