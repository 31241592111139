import { FormControl, FormLabel, Input, FormErrorMessage, Box, HStack } from "@chakra-ui/react"
import { Field, Form, Formik } from "formik"
import { useRouter } from "next/router"
import { UseCase } from "@prisma/client"
import { handleClientSideException } from "lib/errors"
import { useToastContext } from "src/hooks/useToastContext"
import { createOrUpdateUseCase } from "pages/api/models/useCases/mutations/createOrUpdateUseCase"

import BasicModal from "../MenusAndModals/BasicModal"
import PrimaryButton from "../Buttons/Primary"

interface UseCaseModalProps {
  onClose(): void
  useCase?: UseCase
}

const StartWorkflowModal = ({ onClose }: UseCaseModalProps) => {
  const { showError } = useToastContext()

  const validateName = (value: string) => {
    return !value ? "Name is required" : undefined
  }

  const router = useRouter()

  return (
    <BasicModal title="Start a workflow" onClose={() => onClose()} size="xl">
      <Formik
        initialValues={{
          name: "",
        }}
        onSubmit={async (values, actions) => {
          try {
            const updatedUseCase = await createOrUpdateUseCase({
              title: values.name,
              useCaseType: "Deliver a combined dataset",
              externalOrganizations: [],
              additionalContext: "",
            })

            await router.push(`/app/workflow/${updatedUseCase.Workflow.uuid}/requirements`)
          } catch (error) {
            handleClientSideException(error, "Failed to submit use case details", showError)
          } finally {
            actions.setSubmitting(false)
          }
        }}
      >
        {(props) => (
          <Form>
            <Box my={4}>
              <Field name="name" validate={validateName}>
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.name}>
                    <FormLabel htmlFor="name">Assign a name to this Expert Determination workflow</FormLabel>
                    <Input {...field} placeholder="eg: Acme Pharma-DrugX-Ad_Attribution" id="name" />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Box>

            <HStack mt="24px" justifyContent="right">
              <PrimaryButton isDisabled={!props.dirty || !props.isValid} isLoading={props.isSubmitting} type="submit">
                Create
              </PrimaryButton>
            </HStack>
          </Form>
        )}
      </Formik>
    </BasicModal>
  )
}

export default StartWorkflowModal
