import { ReactNode } from "react"

import { Alert } from "@chakra-ui/react"

interface BannerProps {
  children: ReactNode
  height?: string
  marginTop?: string
}

const Banner = ({ children, height = "auto", marginTop = "48px" }: BannerProps) => {
  return (
    <Alert
      background="rgba(134, 73, 196, 0.90)"
      backdropFilter="blur(4px)"
      status="info"
      mb="0px"
      marginTop={marginTop}
      paddingTop="16px"
      paddingBottom="16px"
      paddingLeft="36px"
      paddingRight="36px"
      marginLeft="0"
      justifyContent="space-between"
      position="sticky"
      top="0"
      zIndex={1}
      height={height}
    >
      {children}
    </Alert>
  )
}

export default Banner
