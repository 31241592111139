import { deleteIntakeDocument } from "pages/api/models/intakeDocuments/mutations/deleteIntakeDocument"
import revalidateSWRQueryByCacheKey from "src/helpers/revalidateSWRQueryByCacheKey"
import { handleClientSideException } from "lib/errors"
import { useToastContext } from "src/hooks/useToastContext"
import { updateIntakeRequirement } from "pages/api/models/intakeRequirements/mutations/updateIntakeRequirement"
import { useConfirmContext } from "src/hooks/useConfirmContext"

import Table from "../Tables/Table"
import IntakeDocumentRow, { ExtendedIntakeDocument } from "./IntakeDocumentRow"

const tableHeaders = [{ label: "File name" }, { label: "" }, { label: "Date provided" }, { label: "Provided by" }]

const tableHeadersWithLegalRequirement = [{ label: "Legal requirement" }, ...tableHeaders]

const IntakeDocumentsTable = ({ intakeDocuments, showLegalRequirement = true }: { intakeDocuments: ExtendedIntakeDocument[]; showLegalRequirement?: boolean }) => {
  const { showError } = useToastContext()
  const { confirm } = useConfirmContext()

  const intakeDocumentsByRequirementType = intakeDocuments.reduce((acc, intakeDocument) => {
    acc[intakeDocument.intakeRequirement.type] = [...(acc[intakeDocument.intakeRequirement.type] || []), intakeDocument]
    return acc
  }, {})

  const isLastDocumentForRequirement = (intakeDocumentId: number) => {
    const intakeDocument = intakeDocuments.find((intakeDocument) => intakeDocument.id === intakeDocumentId)
    const intakeDocumentsForRequirement = intakeDocumentsByRequirementType[intakeDocument!.intakeRequirement.type]

    return intakeDocumentsForRequirement.length === 1
  }

  const markRequirementIncomplete = async (intakeRequirementId: number) => {
    try {
      await updateIntakeRequirement({ intakeRequirementId, isCompleted: false })
    } catch (error) {
      handleClientSideException(error, "Error marking requirement incomplete", showError)
    }
  }

  const handleDeleteIntakeDocument = async (intakeDocumentId: number) => {
    try {
      const intakeDocument = intakeDocuments.find((intakeDocument) => intakeDocument.id === intakeDocumentId)

      if (isLastDocumentForRequirement(intakeDocumentId)) {
        await markRequirementIncomplete(intakeDocument!.intakeRequirementId)
      }

      const isConfirmed = await confirm({
        message: "Are you sure you want to delete this file?",
      })

      if (!isConfirmed) return

      await deleteIntakeDocument({ intakeDocumentId })
      await revalidateSWRQueryByCacheKey("getOrganization")
      await revalidateSWRQueryByCacheKey("app:getWorkflow")
    } catch (error) {
      handleClientSideException(error, "Error deleting document", showError)
    }
  }

  return (
    <Table columnHeaders={showLegalRequirement ? tableHeadersWithLegalRequirement : tableHeaders} headerIndent="24px">
      {intakeDocuments.map((intakeDocument) => (
        <IntakeDocumentRow
          key={intakeDocument.id}
          intakeDocument={intakeDocument}
          onDeleteIntakeDocument={() => handleDeleteIntakeDocument(intakeDocument.id)}
          showLegalRequirement={showLegalRequirement}
        />
      ))}
    </Table>
  )
}

export default IntakeDocumentsTable
