import Table from "../Tables/Table"
import DataSourcesRow from "./DataSourcesRow"

const DataSourcesTable = ({ dataSources }) => {
  const tableHeaders = [{ label: "Name" }, { label: "Path" }, { label: "Type" }, { label: "Last Synced" }, { label: "Cadence" }]

  return (
    <Table columnHeaders={tableHeaders} headerIndent="24px">
      {dataSources.map((dataSource) => (
        <DataSourcesRow key={dataSource?.id} dataSource={dataSource} />
      ))}
    </Table>
  )
}

export default DataSourcesTable
