import { Box, HStack, Text, keyframes } from "@chakra-ui/react"
import { FiChevronDown, FiInfo } from "react-icons/fi"
import dayjs from "dayjs"

import Tooltip from "../Indicators/Tooltip"
import { NotStartedIcon, CompletedIcon } from "../Milestones/ProgressIcons"

interface CollapsibleHeaderProps {
  title: string
  isOpen: boolean
  onClick: () => void
  infoText?: string
  isOptional?: boolean
  isCompleted?: boolean
  lastEdited?: {
    name: string
    date: Date
  }
  displayOptions?: {
    completion?: boolean
    lastEdited?: boolean
    showCompletionAnimation?: boolean
  }
}

const completionAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
`

const confettiAnimation = keyframes`
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(var(--x, 10px), var(--y, 10px));
    opacity: 0;
  }
`

const CollapsibleHeader = ({ title, infoText, isOpen, onClick, isOptional, isCompleted, lastEdited: lastEditedBy, displayOptions }: CollapsibleHeaderProps) => {
  return (
    <Box px="24px" py="16px" cursor="pointer" onClick={onClick}>
      <HStack justifyContent="space-between" alignItems="center" gap="24px">
        <HStack justifyContent="space-between" alignItems="center" width="100%">
          <HStack>
            <HStack minWidth="200px" spacing="12px">
              <Text fontSize="18px">{title}</Text>
              {infoText && (
                <Tooltip label={infoText}>
                  <Box>
                    <FiInfo color="#554693" />
                  </Box>
                </Tooltip>
              )}
            </HStack>
            {isOptional && (
              <Text fontStyle="italic" fontSize="16px" color="#00000080">
                Optional
              </Text>
            )}
          </HStack>
          <HStack gap="24px">
            {displayOptions?.lastEdited && lastEditedBy && (
              <Text fontStyle={"italic"} fontSize="16px" color="#00000080">
                {lastEditedBy.name}, {dayjs(lastEditedBy.date).format("M/D")}
              </Text>
            )}
            {displayOptions?.completion && (
              <Box position="relative" animation={displayOptions.showCompletionAnimation ? `${completionAnimation} 0.5s ease-in-out` : undefined}>
                {displayOptions.showCompletionAnimation && (
                  <>
                    {[...Array(8)].map((_, i) => (
                      <Box
                        key={i}
                        position="absolute"
                        top="50%"
                        left="50%"
                        width="4px"
                        height="4px"
                        borderRadius="50%"
                        backgroundColor="#554693"
                        sx={{
                          "--x": `${Math.cos((i * 45 * Math.PI) / 180) * 20}px`,
                          "--y": `${Math.sin((i * 45 * Math.PI) / 180) * 20}px`,
                          animation: `${confettiAnimation} 0.5s ease-out forwards`,
                          transformOrigin: "center",
                        }}
                      />
                    ))}
                  </>
                )}
                {isCompleted ? <CompletedIcon /> : <NotStartedIcon />}
              </Box>
            )}
          </HStack>
        </HStack>
        <Box transform={isOpen ? "rotate(180deg)" : undefined} transition="transform 0.3s">
          <FiChevronDown />
        </Box>
      </HStack>
    </Box>
  )
}

export default CollapsibleHeader
