import { FiPlus } from "react-icons/fi"
import { useDisclosure } from "@chakra-ui/react"
import useGetFeatureFlagState from "src/hooks/getFeatureFlags/useGetFeatureFlagState"

import SecondaryButton from "../Buttons/Secondary"
import UseCaseModal from "./UseCaseModal"
import StartWorkflowModal from "./StartWorkflowModal"

const StartWorkflowCard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data: flagState } = useGetFeatureFlagState("app:getFeatureFlagState")

  const intakeEnabled = flagState?.state["intake-nov-13"]

  const useCaseModal = intakeEnabled ? <StartWorkflowModal onClose={onClose} /> : <UseCaseModal onClose={onClose} />

  return (
    <>
      <SecondaryButton onClick={onOpen}>
        <FiPlus size="16" style={{ marginRight: "8px" }} /> Start a workflow
      </SecondaryButton>

      {isOpen && useCaseModal}
    </>
  )
}

export default StartWorkflowCard
