import { ReactNode, useState } from "react"

import { Box, Divider, VStack, Collapse } from "@chakra-ui/react"

import CollapsibleHeader from "./CollapsibleHeader"
import PrimaryButton from "../Buttons/Primary"

interface CollapsibleProps {
  title: string
  infoText?: string
  children: ReactNode
  isInitiallyOpen?: boolean
  skipButtonText?: string
  isOptional?: boolean
  isCompleted?: boolean
  lastEditedBy?: {
    name: string
    date: Date
  }
  displayOptions?: {
    lastEdited?: boolean
    completion?: boolean
    showCompletionAnimation?: boolean
  }
  noPadding?: boolean
  onSkip: () => void
}

const Collapsible = ({
  title,
  infoText,
  children,
  isInitiallyOpen: isInitiallyOpen = true,
  skipButtonText,
  onSkip,
  isOptional,
  isCompleted,
  lastEditedBy,
  displayOptions,
  noPadding = false,
}: CollapsibleProps) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen)

  const toggleOpen = () => setIsOpen(!isOpen)

  return (
    <VStack border="1px solid #D9D9D9" borderRadius="var(--ui-radius-card)" backgroundColor="white" justifyContent="flex-start" alignItems="left" gap="0px">
      <CollapsibleHeader
        title={title}
        infoText={infoText}
        isOpen={isOpen}
        onClick={toggleOpen}
        isOptional={isOptional}
        isCompleted={isCompleted}
        lastEdited={lastEditedBy}
        displayOptions={displayOptions}
      />
      <Collapse in={isOpen}>
        <VStack justifyContent="flex-start" alignItems="left" gap="0px">
          <Divider my="0px" />
          <Box px={noPadding ? "0px" : "var(--ui-spacing-card-horizontal)"} py={noPadding ? "0px" : "var(--ui-spacing-card-vertical)"}>
            {children}
          </Box>
          {skipButtonText && (
            <PrimaryButton
              m="16px"
              alignSelf="flex-end"
              onClick={() => {
                onSkip()
                toggleOpen()
              }}
            >
              {skipButtonText}
            </PrimaryButton>
          )}
        </VStack>
      </Collapse>
    </VStack>
  )
}

export default Collapsible
