import { User } from "@prisma/client"
import { useToastContext } from "src/hooks/useToastContext"

import Tooltip from "../Indicators/Tooltip"
import TertiaryButton from "../Buttons/Tertiary"

const OrganizationUserRow = ({ user }: { user: User }) => {
  const { showSuccess } = useToastContext()

  const handleCopy = async (text: string): Promise<void> => {
    await navigator.clipboard.writeText(text)
    showSuccess("Saved to clipboard!")
  }

  return (
    <tr
      style={{
        borderTop: "1px solid var(--card-border)",
      }}
    >
      <td
        style={{
          padding: "12px 0px",
          paddingLeft: "24px",
        }}
      >
        {user.name}
      </td>
      <td
        style={{
          padding: "12px 0px",
        }}
      >
        {user.title}
      </td>
      <td
        style={{
          padding: "12px 0px",
        }}
      >
        <Tooltip label="Copy email">
          <TertiaryButton
            onClick={() => {
              void handleCopy(user.email)
            }}
            marginLeft="-8px"
            px="8px"
          >
            {user.email}
          </TertiaryButton>
        </Tooltip>
      </td>
    </tr>
  )
}

export default OrganizationUserRow
