import { ReactNode } from "react"

import { Box, Text } from "@chakra-ui/react"

interface CalloutProps {
  title: string
  children: ReactNode
}

const Callout = ({ title, children }: CalloutProps) => {
  return (
    <Box background="#EDEEFF" mb="0px" padding="16px" marginLeft="0" borderRadius="8px">
      <Text fontSize="lg" fontWeight="bold">
        {title}
      </Text>
      <Box>{children}</Box>
    </Box>
  )
}

export default Callout
