import { Box, Text, Checkbox, HStack } from "@chakra-ui/react"

const DataManagementAndHandlingConfirmation = ({ organizationName, onConfirm }: { organizationName: string; onConfirm: () => void }) => {
  return (
    <Box>
      <Text>Check the following to confirm on behalf of {organizationName}:</Text>
      <HStack alignItems={"flex-start"} py="12px">
        <Checkbox
          my="4px"
          mr="8px"
          id="data-handling-checkbox"
          onChange={(e) => {
            if (e.target.checked) {
              e.target.disabled = true
              onConfirm()
            }
          }}
        />
        <Text as="label" htmlFor="data-handling-checkbox" cursor="pointer">
          Employees are required to take due care to protect confidential information from unauthorized use, access, disclosure, destruction, loss, or alteration. A violation of restricted uses may
          result in termination, providing both monetary and reputational disincentive. Employees agree to not undertake attempts to re-identify the dataset that is being assessed. Employees face
          penalties from their employers for mishandling data including but not limited to termination.
        </Text>
      </HStack>
    </Box>
  )
}

export default DataManagementAndHandlingConfirmation
