import { useEffect, useState, useRef } from "react"

import { FormControl, FormLabel, Input, FormErrorMessage, Box, Radio, RadioGroup, VStack, Checkbox, CheckboxGroup, Textarea, HStack, Text } from "@chakra-ui/react"
import { Field, Form, Formik, FormikProps } from "formik"
import { useRouter } from "next/router"
import { UseCase } from "@prisma/client"
import { handleClientSideException } from "lib/errors"
import { useToastContext } from "src/hooks/useToastContext"
import { createOrUpdateUseCase } from "pages/api/models/useCases/mutations/createOrUpdateUseCase"
import revalidateSWRQueryByCacheKey from "src/helpers/revalidateSWRQueryByCacheKey"

import BasicModal from "../MenusAndModals/BasicModal"

interface UseCaseModalProps {
  onClose(): void
  useCase?: UseCase
}

const USE_CASE_TYPES = ["Deliver a combined dataset", "Deliver an insight based on analysis of a combined dataset"]

const EXTERNAL_ORGANIZATIONS = ["Data retailer", "University"]

const UseCaseModal = ({ onClose, useCase }: UseCaseModalProps) => {
  const formikRef = useRef<FormikProps<{
    name: string
    type: string
    externalOrganizations: string[]
    additionalContext: string
  }> | null>(null)
  const { showError } = useToastContext()

  const [isOtherSelected, setIsOtherSelected] = useState(false)
  const [otherValue, setOtherValue] = useState("")

  const [isOtherTypeSelected, setIsOtherTypeSelected] = useState(false)
  const [otherTypeValue, setOtherTypeValue] = useState("")

  const getCheckboxValue = (isOtherSelected, field, otherValue) => {
    const filteredValue = field.value.filter((v) => v !== otherValue && v !== "OTHER")

    if (isOtherSelected) {
      return [...filteredValue, "OTHER"]
    }
    return filteredValue
  }

  const validateName = (value: string) => {
    return !value ? "Name is required" : undefined
  }

  const validateType = (value: string) => {
    return !value ? "Type is required" : undefined
  }

  useEffect(() => {
    if (useCase?.useCaseType && !USE_CASE_TYPES.includes(useCase.useCaseType)) {
      setIsOtherTypeSelected(true)
      setOtherTypeValue(useCase.useCaseType)
    }

    const otherExternalOrganization = useCase?.externalOrganizations?.find((v) => !EXTERNAL_ORGANIZATIONS.includes(v))

    if (useCase?.externalOrganizations?.length && otherExternalOrganization) {
      setIsOtherSelected(true)
      setOtherValue(otherExternalOrganization)
    }
  }, [useCase])

  const router = useRouter()

  return (
    <BasicModal
      title="Use case details"
      onClose={() => onClose()}
      size="xl"
      showActionButtons
      actionText={useCase ? "Update use case details" : "Continue with workflow"}
      onAction={() => formikRef.current?.submitForm()}
      isActionLoading={formikRef.current?.isSubmitting}
      isActionDisabled={!formikRef.current?.dirty || !formikRef.current?.isValid}
    >
      <Formik
        innerRef={formikRef}
        initialValues={{
          name: useCase?.title || "",
          type: useCase?.useCaseType || "Deliver a combined dataset",
          externalOrganizations: useCase?.externalOrganizations || [],
          additionalContext: useCase?.additionalContext || "",
        }}
        onSubmit={async (values, actions) => {
          try {
            const updatedUseCase = await createOrUpdateUseCase({
              title: values.name,
              useCaseType: values.type,
              externalOrganizations: values.externalOrganizations,
              additionalContext: values.additionalContext,
              ...(useCase && { id: useCase.id }),
            })

            if (!useCase) {
              await router.push(`/app/workflow/${updatedUseCase.workflowId}/use-case`)
            } else {
              await revalidateSWRQueryByCacheKey("app:getWorkflow")
              onClose()
            }
          } catch (error) {
            handleClientSideException(error, "Failed to submit use case details", showError)
          } finally {
            actions.setSubmitting(false)
          }
        }}
      >
        {(props) => (
          <Form>
            <Box my={4}>
              <Field name="name" validate={validateName}>
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.name}>
                    <FormLabel htmlFor="name">Give this Expert Determination project a brief name based on its use case.</FormLabel>
                    <Input {...field} placeholder="eg: Tokenized data for health economics and outcomes research" id="name" />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Box>
            <Box my="24px">
              <Field name="type" validate={validateType}>
                {({ field, form }) => (
                  <FormControl isInvalid={!!form.errors.type && !!form.touched.type}>
                    <FormLabel htmlFor="type">Which of the following represents your project’s use case? This will inform which risk model is applied by Integral.</FormLabel>
                    <RadioGroup
                      id="type"
                      value={isOtherTypeSelected ? "OTHER" : field.value}
                      onChange={(val) => {
                        if (val === "OTHER") {
                          setIsOtherTypeSelected(true)
                        } else {
                          setIsOtherTypeSelected(false)
                          form.setFieldValue("type", val)
                        }
                      }}
                    >
                      <VStack spacing="12px" alignItems="left" marginTop="12px">
                        {USE_CASE_TYPES.map((type, i) => (
                          <Radio value={type} key={i}>
                            {type}
                          </Radio>
                        ))}
                        <HStack>
                          <Radio value="OTHER">{!isOtherTypeSelected && <Text>Other</Text>}</Radio>
                          {isOtherTypeSelected && (
                            <Input
                              value={otherTypeValue}
                              onBlur={(e) => {
                                if (e.target.value === "") {
                                  setIsOtherTypeSelected(false)
                                }
                              }}
                              marginTop="-16px"
                              position="relative"
                              top="8px"
                              onChange={(e) => {
                                setOtherTypeValue(e.target.value)

                                if (e.target.value !== "") {
                                  form.setFieldValue("type", e.target.value)
                                }
                              }}
                            />
                          )}
                        </HStack>
                      </VStack>
                    </RadioGroup>
                    <FormErrorMessage>{form.errors.type}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Box>
            <Box my="24px">
              <Field name="externalOrganizations">
                {({ field, form }) => (
                  <FormControl isInvalid={!!form.errors.externalOrganizations && !!form.touched.externalOrganizations}>
                    <FormLabel htmlFor="externalOrganizations">
                      What type of external organizations will have access to the data post- determination? This will inform the appropriate level of risk evaluation.
                    </FormLabel>
                    <HStack>
                      <CheckboxGroup
                        value={getCheckboxValue(isOtherSelected, field, otherValue)}
                        onChange={(val) => {
                          let newValue

                          if (isOtherSelected) {
                            newValue = [...val.filter((v) => v !== "OTHER"), otherValue]
                          } else {
                            newValue = val.filter((v) => v !== "OTHER")
                          }

                          if (val.includes("OTHER") && !isOtherSelected) {
                            setIsOtherSelected(true)
                          } else if (!val.includes("OTHER") && isOtherSelected && otherValue === "") {
                            setIsOtherSelected(false)
                            setOtherValue("")
                            newValue = val.filter((v) => v !== "OTHER")
                          }

                          form.setFieldValue("externalOrganizations", newValue)
                        }}
                      >
                        <HStack justifyContent="space-between" marginTop="12px" spacing="44px">
                          {EXTERNAL_ORGANIZATIONS.map((org, i) => (
                            <Checkbox value={org} key={i}>
                              {org}
                            </Checkbox>
                          ))}
                          <Checkbox value="OTHER" isChecked={isOtherSelected}>
                            {!isOtherSelected && <Text>Other</Text>}
                          </Checkbox>{" "}
                        </HStack>
                      </CheckboxGroup>
                      {isOtherSelected && (
                        <Input
                          width="200px"
                          value={otherValue}
                          onBlur={(e) => {
                            if (e.target.value === "") {
                              setIsOtherSelected(false)
                            }
                          }}
                          height="36px"
                          position="relative"
                          top="4px"
                          onChange={(e) => {
                            setOtherValue(e.target.value)

                            let newValue

                            if (e.target.value !== "") {
                              newValue = [...field.value.filter((v) => v !== "OTHER" && v !== otherValue), e.target.value]
                            } else {
                              newValue = field.value.filter((v) => v !== "OTHER" && v !== otherValue)
                            }

                            form.setFieldValue("externalOrganizations", newValue)
                          }}
                        />
                      )}
                    </HStack>
                    <FormErrorMessage>{form.errors.externalOrganizations}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Box>
            <Box my="24px">
              <Field name="additionalContext">
                {({ field, form }) => (
                  <FormControl isInvalid={!!form.errors.additionalContext && !!form.touched.additionalContext}>
                    <FormLabel htmlFor="additionalContext">
                      Share anything else about how the dataset will be used post-determination. Provide as much detail as possible about use cases, future stakeholders, and business applications.
                    </FormLabel>
                    <Textarea {...field} id="additionalContext" />
                    <FormErrorMessage>{form.errors.additionalContext}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Box>
          </Form>
        )}
      </Formik>
    </BasicModal>
  )
}

export default UseCaseModal
