import { DataSourceConnector } from "./cosmic/images"

export const dataSourceConnectors = [
  {
    name: "Azure Blob Storage",
    icon: DataSourceConnector.AZURE_BLOB_STORAGE,
    sourceType: "azure-blob-storage",
  },
  {
    name: "S3",
    icon: DataSourceConnector.S3,
    sourceType: "s3",
  },
  {
    name: "GCS",
    icon: DataSourceConnector.GCS,
    sourceType: "gcs",
  },
  {
    name: "SFTP",
    icon: DataSourceConnector.SFTP,
    sourceType: "sftp",
  },
  {
    name: "BigQuery",
    icon: DataSourceConnector.BIG_QUERY,
    sourceType: "bigquery",
  },
  {
    name: "Snowflake",
    icon: DataSourceConnector.SNOWFLAKE,
    sourceType: "snowflake",
  },
]
